import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';

import {
  Grid,
  Button,
  Container,
  Stack,
  Chip,
  InputAdornment,
  Typography,
  Box
} from '@mui/material';

import WineBarTwoToneIcon from '@mui/icons-material/WineBarTwoTone';
import LiquorIcon from '@mui/icons-material/Liquor';
import TagIcon from '@mui/icons-material/Tag';

function MenuListItem(props){

  const { key, item } = props;
  const navigate = useNavigate();

  const handleMenuItemClick = () => {
    navigate('/item/'+item.key);
  };

  return (
    <Grid
      item
      sx={{height:'72px'}}
      key={key}
      onClick={handleMenuItemClick}
      sm={12} md={12} lg={12} xs={12}>
      <Grid
        container
        direction="row"
        sx={{
          position:'relative',
          height:'100%',
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={0} sm={1} md={3}>

        </Grid>
        <Grid item xs="auto">
          <Typography element="p" sx={{
            fontFamily: 'Gowun Bold',
            cursor:'pointer',
            textAlign:"left", fontWeight:900, mb:'12px', fontSize:{xs:'16px', sm:'20px'}}}>
            {item && item.title.kr}
          </Typography>
        </Grid>
        <Grid item xs>

        </Grid>
        <Grid item xs={4}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{cursor:'pointer'}}
            spacing={0}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}>
              <WineBarTwoToneIcon
                fontSize="small"/>
              <Typography element="p" sx={{
                fontFamily: 'Gowun Bold',
                textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
                {item && item.price.shot.toLocaleString()}
              </Typography>
            </Stack>
            {item && item.price.bottle > 0 && <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{marginBottom:'12px'}}
              spacing={2}>
              <LiquorIcon
                fontSize="small"/>
              <Typography element="p" sx={{
                fontFamily: 'Gowun Bold',
                textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
                {item && item.price.bottle.toLocaleString()}
              </Typography>
            </Stack>}
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MenuListItem;
