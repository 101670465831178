import axios from 'axios';
import getToken from '../../utils/getToken'

export default class ProductController{
  static deleteProduct(query, _success, _error){
    const token = getToken();
    axios({
      method : "GET",
      url : `/api/product/item/${query.key}/remove`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static createProduct(data, _success, _error){
    const token = getToken();
    let formData = new FormData();

    formData.append('key', data.key);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('comment', data.comment);
    formData.append('short', data.short);
    formData.append('type', data.type);

    formData.append('option_s', data.option_s);
    formData.append('option_t', data.option_t);
    formData.append('option_f', data.option_f);

    formData.append('price_shot', data.price_shot);
    formData.append('price_bottle', data.price_bottle);

    formData.append('prev_images', JSON.stringify(data.images.origin));
    console.log(data);
    data.images.update.forEach((image) => {
        formData.append('images', image.file);
    });

    axios({
      method: "POST",
      url:`/api/product/item/create`,
      data:formData,
      headers: {
        Authorization: 'Bearer ' + token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }
      _error(error);
    });
  }
  static updateProduct(data, _success, _error){
    const token = getToken();
    let formData = new FormData();

    formData.append('key', data.key);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('comment', data.comment);
    formData.append('short', data.short);
    formData.append('type', data.type);

    formData.append('option_s', data.option_s);
    formData.append('option_t', data.option_t);
    formData.append('option_f', data.option_f);

    formData.append('price_shot', data.price_shot);
    formData.append('price_bottle', data.price_bottle);

    formData.append('prev_images', JSON.stringify(data.images.origin));
    console.log(data);
    data.images.update.forEach((image) => {
        formData.append('images', image.file);
    });

    axios({
      method: "POST",
      url:`/api/product/item/${data.id}/update`,
      data:formData,
      headers: {
        Authorization: 'Bearer ' + token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }
      _error(error);
    });
  }
  static getProduct(query, _success, _error){
    const token = getToken();
    axios({
      method : "GET",
      url : `/api/product/item/${query.id}`,
      /*headers: {
        Authorization: 'Bearer ' + token
      },*/
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static updateProductView(query, _success, _error){
    const token = getToken();
    axios({
      method: "GET",
      url : `/api/product/item/${query.id}/view`,
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getProducts(query, _success, _error){

    const token = getToken();
    console.log(query);
    axios({
      method: "POST",
      url:`/api/product`,
      /*headers: {
        Authorization: 'Bearer ' + token
      },*/
      data : query
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getFavoriteProducts(query, _success, _error){
    const token = getToken();
    axios({
      method: 'GET',
      url:  `/api/product/favorite?number=${query.number}`,
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getBrandsDetail(query, _success, _error){
    const token = getToken();
    axios({
      method: 'GET',
      url:  '/api/brand/alias',
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
}
