import React, {useEffect, useState, useRef} from 'react';

import {
  Grid,
  Button,
  Container,
  Stack,
  Chip,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  TextField,
  Divider,
  InputLabel,
  Input,
  FormControl,
  OutlinedInput
} from '@mui/material';

import { IMaskInput } from 'react-imask';

import PropTypes from 'prop-types';

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import ReservationController from '../../apis/reservation/ReservationController';
import Footer from '../../components/common/Footer';
import moment from 'moment';
import 'moment/locale/ko'

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
moment.locale('ko')


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-0000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function ReservationPage(props){

  const [date, setDate] = React.useState(moment());

  const [error, setError] = React.useState({
    number : false,
    name : false,
    people : false,
    detail : false
  });

  const [values, setValues] = React.useState({
    number: '',
    name: '',
    people: 0,
    detail : ""
  });

  const handleReservation = () => {
    if(values.name.length < 2){
      setError((prev) => ({ ...prev, name:false }));
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };


  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative', boxSizing:'border-box', overflow:'scroll' }}>
      <Box sx={{width:'100%', position:'relative', minHeight:{xs:'60vh',md:'40vh'}, padding:'72px 0px'}}>
        <img src="/images/eaudevie_image.jpg"
          style={{position:'absolute', width:'100%', height:'100%', top:'0px', left:'0px', right:'0px', bottom:'0px', objectFit:'cover'}}/>
        <Box sx={{
          position:'absolute',
          width:'100%',
          height:'100%',
          top:'0px', left:'0px', right:'0px', bottom:'0px',
          background:'linear-gradient(180deg, rgba(0,0,0,.3), #ffffff)'}}>
          <Stack
            sx={{position:'absolute', width:'100%', height:'100%', top:'0px', left:'0px', right:'0px', bottom:'0px'}}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            <Typography element="p" sx={{
              fontFamily: 'Gowun Bold',
              textAlign:"center", color:'#222222', fontSize:'36px', fontWeight:'900', maxWidth:'720px' }}>
              오드비 대관하기
            </Typography>
            <Typography element="p" sx={{
              fontFamily: 'Gowun Bold',
              textAlign:"center", color:'#222222', fontSize:'20px', fontWeight:'900', maxWidth:'720px' }}>
              모임이 있으신 경우 대관하여 프라이빗하게 즐기실 수 있습니다
            </Typography>
            <Typography element="p" sx={{
              fontFamily: 'Gowun Bold',
              textAlign:"center", color:'#555555', fontSize:'16px', fontWeight:'900', maxWidth:'720px' }}>
              대관을 하시는 경우 대관료가 발생하며, 주문 비용은 별도입니다.
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Typography element="p" sx={{
                  fontFamily: 'Gowun Bold',
                  textAlign:"center", color:'#222222', fontSize:'18px', fontWeight:'900', maxWidth:'720px' }}>
                  일 ~ 목
                </Typography>
                <Typography element="p" sx={{
                  fontFamily: 'Gowun Bold',
                  textAlign:"center", color:'#222222', fontSize:'16px', fontWeight:'900', maxWidth:'720px' }}>
                  300,000 원
                </Typography>
              </Stack>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Typography element="p" sx={{
                  fontFamily: 'Gowun Bold',
                  textAlign:"center", color:'#222222', fontSize:'18px', fontWeight:'900', maxWidth:'720px' }}>
                  금, 토
                </Typography>
                <Typography element="p" sx={{
                  fontFamily: 'Gowun Bold',
                  textAlign:"center", color:'#222222', fontSize:'16px', fontWeight:'900', maxWidth:'720px' }}>
                  400,000 원
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box sx={{position:'relative', width:'100%'}}>
        <Container sx={{width:'100%', position:'relative', paddingBottom:'36px'}}>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              justifyContent: "flex-start",
              alignItems: "center",
              width:'100%',
              position:'relative'
            }}>
            <Box sx={{position:'relative', width:'100%', maxWidth:'480px'}}>
              <DateCalendar
                  minDate={moment()}
                  maxDate={moment().add(4,'months')}
                  value={date} onChange={(newValue) => setDate(newValue)} />
            </Box>
            <Box sx={{position:'relative', width:'100%', maxWidth:'480px'}}>
              <Typography element="p" sx={{
                fontFamily: 'Gowun Bold',
                mb:'12px',
                textAlign:"left", color:'#222222', fontSize:'16px', fontWeight:'900', maxWidth:'720px' }}>
                예약일자
              </Typography>
              <Typography element="p" sx={{
                fontFamily: 'Gowun Bold',
                textAlign:"left", color:'#222222', fontSize:'16px', fontWeight:'900', maxWidth:'720px' }}>
                {moment(date).format('LL')}
              </Typography>
            </Box>
            <Box sx={{position:'relative', width:'100%', maxWidth:'480px'}}>
              <Typography element="p" sx={{
                fontFamily: 'Gowun Bold',
                mb:'12px',
                textAlign:"left", color:'#222222', fontSize:'16px', fontWeight:'900', maxWidth:'720px' }}>
                예약자명
              </Typography>
              <TextField id="outlined-basic" label="예약자명" variant="outlined"
                name="name"
                sx={{width:'100%', position:'relative'}}/>
            </Box>
            <Box sx={{position:'relative', width:'100%', maxWidth:'480px'}}>
              <Typography element="p" sx={{
                fontFamily: 'Gowun Bold',
                mb:'12px',
                textAlign:"left", color:'#222222', fontSize:'16px', fontWeight:'900', maxWidth:'720px' }}>
                예상 인원수
              </Typography>
              <TextField id="outlined-basic" label="인원 수 (명)" variant="outlined"
                type="number"
                name="people"
                sx={{width:'100%', position:'relative'}}/>
            </Box>
            <Box sx={{position:'relative', width:'100%', maxWidth:'480px'}}>
              <Typography element="p" sx={{
                fontFamily: 'Gowun Bold',
                mb:'12px',
                textAlign:"left", color:'#222222', fontSize:'16px', fontWeight:'900', maxWidth:'720px' }}>
                전화번호
              </Typography>
              <FormControl variant="standard" sx={{width:'100%'}}>
                <OutlinedInput
                  value={values.number}
                  onChange={handleChange}
                  name="number"
                  hint="010-1234-1234"
                  id="formatted-text-mask-input"
                  inputComponent={TextMaskCustom}
                />
              </FormControl>
            </Box>
            <Box sx={{position:'relative', width:'100%', maxWidth:'480px'}}>
              <Typography element="p" sx={{
                fontFamily: 'Gowun Bold',
                mb:'12px',
                textAlign:"left", color:'#222222', fontSize:'16px', fontWeight:'900', maxWidth:'720px' }}>
                추가 요청사항
              </Typography>
              <TextField
                id="outlined-multiline-static"
                label="추가 요청사항"
                multiline
                name="detail"
                sx={{width:'100%', position:'relative'}}
                rows={4}/>
            </Box>
            <Box sx={{position:'relative', width:'100%', maxWidth:'480px', mb:'36px'}}>
              <Button
                variant="outlined"
                onClick={handleReservation}
                sx={{width:'100%', position:'relative', height:'56px'}}>예약하기</Button>
            </Box>
            <Box sx={{position:'relative', width:'100%', maxWidth:'480px', mb:'24px'}}>
              <Typography element="p" sx={{
                fontFamily: 'Gowun Bold',
                mb:'12px',
                textAlign:"left", color:'#666666', fontSize:'16px', fontWeight:'500', maxWidth:'720px' }}>
                * 대관은 최소 일주일 전에 부탁드립니다.
              </Typography>
              <Typography element="p" sx={{
                fontFamily: 'Gowun Bold',
                mb:'12px',
                textAlign:"left", color:'#666666', fontSize:'16px', fontWeight:'500', maxWidth:'720px' }}>
                * 대관으로 예약하신 시간 전에 확인 전화를 한 번 드립니다.
              </Typography>
              <Typography element="p" sx={{
                fontFamily: 'Gowun Bold',
                mb:'12px',
                textAlign:"left", color:'#666666', fontSize:'16px', fontWeight:'500', maxWidth:'720px' }}>
                * 취소는 전화로 미리 부탁드립니다.
              </Typography>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Footer/>
    </Box>);
}

export default ReservationPage;
